<template>
  <ion-page class="page scrollable bg-transparent">
    <h1>
      <strong
        >Posts by
        <router-link
          :to="{ name: 'character-profile-new', params: { slug: inActionCharacter.slug } }"
          class="text-black"
        >
          {{ charName }}
        </router-link></strong
      >
    </h1>

    <!-- <character-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" /> -->
    <char-posts-tabs
      class="mb-2"
      :btn-clr="btnColor"
      :text-clr="textClr"
      :tabs="tabs"
      :text-btn-clr="btnCheckedTextClr"
      :count="blabsCount"
      :active-index="tabIndex"
      @tab-changed="tabChanged"
    />
    <all-posts :posts="userPosts" :total-posts="countPost" @loadMore="loadMore" />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Getter } from 's-vuex-class';
import CharacterTabs from './components/CharacterTabs.vue';
import { characterPostsTabs } from '@/shared/statics/tabs';
import { Character, Paging, Tab } from '@/shared/types/static-types';
import AllPosts from '@/shared/components/Posts/AllPosts.vue';
import namespace from '@/shared/store/namespace';
import { getPosts, getPostsCount } from '@/shared/actions/blabs';
import CharPostsTabs from '@/shared/pages/blabs/components/CharPostsTabs.vue';
import constants from '@/shared/statics/constants';

import { getNextPage } from '@/shared/helpers/pagination';

@Options({
  name: 'CharacterBlabPage',
  components: {
    CharacterTabs,
    AllPosts,
    CharPostsTabs,
  },
})
export default class CharacterBlabPage extends Vue {
  public tabs = characterPostsTabs;
  public currentTab: string = 'blabs';
  public tabIndex = 0;
  userPosts = [];
  countPost = '';
  public pageNum = 1;
  public blabsCount = {};

  public paging: Paging | null = null;

  @Action('getCharacterFromSlug', { namespace: namespace.CharactersModule })
  public getCharacterFromSlug!: any;

  @Getter('inActionCharacter', { namespace: namespace.CharactersModule })
  public inActionCharacter!: Character;

  public get charName() {
    return this.inActionCharacter?.info?.name;
  }

  public get charId() {
    return this.inActionCharacter.id;
  }

  get = get;

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    this.currentTab = value;
    router.replace({ name: 'char-posts', query: { tab: value } });
    this.userPosts = [];
    this.fetchPosts(this.getCurrVal);
  }

  public async requestLoadMore() {
    try {
      const { results, ...paging } = await getNextPage(this.paging!);
      this.userPosts = this.userPosts.concat(results);
      this.paging = paging;
    } catch (e) {}
  }

  public get characterSlug() {
    const router = useRouter();
    return router.currentRoute.value.params.slug;
  }

  public async fetchPostsCount() {
    const resp = await getPostsCount({ character_in_post: this.charId });
    this.blabsCount = resp;
  }

  public async getCharacter() {
    await this.getCharacterFromSlug(this.characterSlug);
    this.fetchPosts(this.getCurrVal);
    this.fetchPostsCount();
  }

  public get getCurrVal() {
    const value = this.currentTab === 'blabs' ? '' : this.currentTab;
    return value;
  }

  public loadMore() {
    this.pageNum++;
    this.fetchPosts(this.getCurrVal);
  }

  public get btnColor() {
    return '#b140e6';
  }

  public get textClr() {
    return '#214163';
  }
  public get btnCheckedTextClr() {
    return '#fff';
  }
  public async fetchPosts(value: any) {
    let res: any;

    if (isEmpty(value)) {
      res = await getPosts(this.pageNum, constants.defaultPageSize, {
        character_in_post: this.inActionCharacter.id,
        ordering: '-created',
      });
    } else {
      res = await getPosts(this.pageNum, constants.defaultPageSize, {
        character_in_post: this.inActionCharacter.id,
        ordering: '-created',
        category: value,
      });
    }
    this.countPost = res.count;
    this.userPosts = this.userPosts.concat(res.results);
  }

  public async created() {
    const router = useRouter();
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    this.getCharacter();

    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === router.currentRoute.value.query.tab)
    );
    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = router.currentRoute.value.query.tab;
    }
  }
}
</script>
<style lang="sass" scoped></style>
