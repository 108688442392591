<template>
  <ion-segment
    scrollable
    mode="md"
    color="primary"
    class="tabs compact no-shadow bg-transparent"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button v-for="(tab, index) of tabs" :key="index" class="shrink bg-transparent" :value="index">
      <ion-label>{{ tab.name }}</ion-label>
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Tab } from '@/shared/types/static-types';

@Options({
  name: 'CharacterTabs',
  components: {},
})
export default class CharacterTabs extends Vue {
  @Prop({ type: Array }) public tabs!: Tab[];
  @Prop({ type: Number, default: 0 }) public activeIndex!: number;

  public segmentChanged($event: CustomEvent) {
    const tabIndex = $event.detail.value as number;
    const tab = this.tabs[tabIndex];

    this.$emit('update:activeIndex', tabIndex);
    this.$emit('tab-changed', tab);
  }
}
</script>

<style scoped lang="sass">
.shrink
  flex: none !important
.tabs
  min-height: 30px !important
</style>
